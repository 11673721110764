import React, { useEffect, useState ,useRef} from 'react';
import { useDispatch } from 'react-redux';
import { setFilterCategory } from '../../model/reducer/productFilterReducer';
import { Tree } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';



const ShowCategory = ({ data ,closeSidebarRef }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);



    useEffect(() => {
        if (data?.length > 0) {
            const cat = transformCategoryData(data);
            setTreeData(cat);
        }
    }, [data]);

    const transformCategoryData = (categories) => {
        return categories?.map((category) => ({
            title: (
                <div className='d-flex category-node align-items-center'
                    onClick={() => {
                        selectCategory(category)}}
                    style={{ textDecoration: 'none', color: 'var(--font-color)' , cursor: 'pointer' , fontSize: '13px', 
                        fontWeight: 'bold', 
                       }}
                >
                    {category.name}
                </div>
            ),
            key: category.id,
            children: category.cat_active_childs.length > 0
                ? transformCategoryData(category.cat_active_childs)
                : [],
        }));
    };

    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
    };

    const handleExpandCollapse = (node) => {
        const newExpandedKeys = expandedKeys.includes(node.key)
            ? expandedKeys.filter((key) => key !== node.key)
            : [...expandedKeys, node.key];
        setExpandedKeys(newExpandedKeys);
    };

    const selectCategory = (category) => {
        dispatch(setFilterCategory({ data: category.id }));
       
        if (category?.has_child === true) {
            // navigate(`/category/${category.slug}`);

        } else {
            if (closeSidebarRef?.current) {
                closeSidebarRef.current.click();
            }
            navigate('/products');

        }
       
    };

  /*   const renderTitle = (node) => {
        const isExpanded = expandedKeys.includes(node.key);
        const hasChildren = node.children && node.children.length > 0;

        return (
            <div className='d-flex category-node align-items-center' style={{ color: 'var(--font-color)'}}>
                <div >{node.title}{' '}</div>
                {hasChildren && (
                    isExpanded
                        ? <MinusOutlined className='ml-5' onClick={() => handleExpandCollapse(node)} />
                        : <PlusOutlined className='ml-5' onClick={() => handleExpandCollapse(node)} />
                )}
            </div>
        );
    }; */
    const renderTitle = (node) => {
        const isExpanded = expandedKeys.includes(node.key);
        const hasChildren = node.children && node.children.length > 0;
    
        const toggleExpandCollapse = () => {
            const newExpandedKeys = isExpanded
                ? expandedKeys.filter((key) => key !== node.key)
                : [...expandedKeys, node.key];
            setExpandedKeys(newExpandedKeys);
        };
    
        return (
            <div
                className='d-flex category-node align-items-center'
                style={{ color: 'var(--font-color)', cursor: 'pointer' }}
                onClick={toggleExpandCollapse} // إضافة الحدث هنا
            >
                <div>{node.title}{' '}</div>
                {hasChildren &&
                    (isExpanded ? (
                        <MinusOutlined className='ml-5' onClick={(e) => { e.stopPropagation(); toggleExpandCollapse(); }} />
                    ) : (
                        <PlusOutlined className='ml-5' onClick={(e) => { e.stopPropagation(); toggleExpandCollapse(); }} />
                    ))}
            </div>
        );
    };

    const renderTreeNodes = (data) =>
        data?.map((item) => ({
            ...item,
            title: renderTitle(item), // Pass custom title for each node
            children: item.children.length > 0 ? renderTreeNodes(item.children) : [],
        }));

    return (
        <Tree
            treeData={renderTreeNodes(treeData)}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            defaultExpandAll={false}
            showLine={false}
            switcherIcon={null}
        />
    );
};

export default ShowCategory;